import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Column, Group } from '../../table';

@Component({
  selector: 'app-table-card',
  templateUrl: './table-card.component.html',
  styleUrls: ['./table-card.component.scss']
})
export class TableCardComponent implements OnInit {
  @Input()
  public heading = '';

  public get actionsLabel() {
    return `Table Actions for ${this.heading}`;
  }

  @Input()
  public empty = false;

  @Input()
  public loading = true;

  @Output()
  public download = new EventEmitter();

  @Input()
  public dataId = '';

  @Input()
  public columns: Column[];

  @Input()
  public groups: Group[];

  @Input()
  public data: any;

  constructor() { }

  ngOnInit() { }

  public initiateDownload() {
    this.download.emit();
  }
}
