import { Input, Component, ElementRef } from '@angular/core';

import { D3BarChartComponent } from '../bar-chart/bar-chart.component';
import { SidebarService } from '../../../../../layout/sidebar/sidebar.service';
import { d3Selection, StackedBarChart, YAxis } from 'power-chart';

export interface Key {
  key: string;
  label: string;
}

@Component({
  selector: 'app-d3-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss']
})
export class D3StackedBarChartComponent extends D3BarChartComponent {
  protected chartElementSelector = '[data-stacked-bar-chart-container]';

  @Input()
  public keys: Key[] = [] as Key[];

  constructor(el: ElementRef, sidebar: SidebarService) {
    super(el, sidebar);
  }

  initChart() {
    this.yAxisSelect = d3Selection.select(this.axisEl)
      .attr('height', this.height); // TODO Are these necessary?
    this.detailsSelect = d3Selection.select(this.detailsEl)
      .attr('height', this.height);

    // Draw the y axis so we can determine how much space it takes up.
    this.yAxis = new YAxis();
    this.yAxis
      .height(this.height)
      .showTicks(false)
      .render(this.yAxisSelect, []);

    // Draw the chart accounting for the y axis padding
    const detailsPad = {
      ...this.padding,
      top: this.padding.top + this.yAxis.requiredPadding().top
    };

    // TODO Draw the y axis across the full width.
    this.details = new StackedBarChart();
    this.details
      .name(this.chartName)
      .showValueAxisText(false)
      .cornerRadius(8)
      .cyclicClassCount(this.numberOfCustomStyles)
      // TODO Make the min width of the y axis the full width.
      .width(this.width)
      .height(this.height)
      .useFixedBarStep(true)
      // TODO Change this on mobile.
      .barStep(125)
      .dataInnerPadding(0.4)
      .dataOuterPadding(0.4)
      .padding(detailsPad)
      .keys(this.keys.map((k: Key) => k.key))
      .valueAccessor(this.valueAccessor)
      .labelAccessor(this.labelAccessor)
      .categoryFormatter(this.categoryFormatter)
      .valueFormatter(this.valueFormatter)
      .render(this.detailsSelect, []);

    // if (this.speed !== undefined && this.speed !== null) {
    //   this.details.speed(this.speed);
    // }

    // Re-render the y axis to:
    // - shift left and down to by its required padding.
    // - reduce the height to account for the x axis.
    this.repositionAxis();
    this.yAxis
      .scale(this.details.valueScale())
      .redraw();

    this.svg = d3Selection.select(this.el.nativeElement);

    this.details.getRoot()
      .classed('details', true);
  }
}
