import { NgModule } from '@angular/core';
import { Angulartics2Module } from 'angulartics2';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipModule } from 'ng2-tooltip-directive';

import {
  ProgressBarComponent,
  CardComponent,
  SubCardComponent,
  TableCardComponent,
  ChartLoaderDirective,
  D3BarChartComponent,
  D3StackedBarChartComponent,
  AsyncTableComponent,
  TableComponent,
  DropdownComponent,
  DropdownItemDirective,
  DropdownButtonDirective,
  ZoomableBarChartComponent,
  HistogramBarChartComponent,
  D3DoughnutChartComponent,
  TableColumnFilterComponent,
  TableFormatterPipe,
  CollapseComponent,
  PercentBarComponent,
  FavoriteListComponent,
} from './index';

@NgModule({
  imports: [
    CommonModule,
    NgbDropdownModule,
    TooltipModule,
    NgxPaginationModule,
    Angulartics2Module,
  ],
  declarations: [
    CardComponent,
    SubCardComponent,
    TableCardComponent,
    ProgressBarComponent,
    ChartLoaderDirective,
    AsyncTableComponent,
    TableComponent,
    D3BarChartComponent,
    D3StackedBarChartComponent,
    HistogramBarChartComponent,
    D3DoughnutChartComponent,
    ZoomableBarChartComponent,
    DropdownComponent,
    DropdownItemDirective,
    DropdownButtonDirective,
    TableColumnFilterComponent,
    TableFormatterPipe,
    CollapseComponent,
    PercentBarComponent,
    FavoriteListComponent,
  ],
  exports: [
    CardComponent,
    SubCardComponent,
    TableCardComponent,
    ProgressBarComponent,
    ChartLoaderDirective,
    AsyncTableComponent,
    TableComponent,
    D3BarChartComponent,
    D3StackedBarChartComponent,
    HistogramBarChartComponent,
    D3DoughnutChartComponent,
    ZoomableBarChartComponent,
    DropdownComponent,
    DropdownItemDirective,
    DropdownButtonDirective,
    TableColumnFilterComponent,
    TableFormatterPipe,
    CollapseComponent,
    PercentBarComponent,
    FavoriteListComponent,
  ],
  entryComponents: [
    D3BarChartComponent,
    D3DoughnutChartComponent,
    D3StackedBarChartComponent,
    HistogramBarChartComponent,
    AsyncTableComponent,
    TableComponent
  ]
})
export class SharedComponentsModule { }
