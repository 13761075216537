import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostBinding,
  ElementRef,
  ChangeDetectionStrategy,
  ViewChild,
  OnInit,
  SimpleChange,
  ChangeDetectorRef
} from '@angular/core';
import { PaginationControlsDirective } from 'ngx-pagination';
import { DisplayType, ChartIdMap } from 'power-chart';

import { Column } from './column';
import { Group } from './group';
import { RowConfig } from './row-config';
import { Filter } from './filter';
import { CellChangeData } from './cell-change-data';

export const defaultItemsPerPage = 8;

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnInit {
  private dataChanges = new SimpleChange(null, null, false);
  private dataInitialized = false;
  protected _initialized = false;

  @HostBinding('class.paginated')
  public get paginated(): boolean {
    return (this.data && this.data.length > this.itemsPerPage);
  }

  @Input()
  public chartId: number;

  get codename(): string {
    return ChartIdMap[this.chartId] ? ChartIdMap[this.chartId].codename : '';
  }

  @ViewChild('p', { static: true })
  paginator: PaginationControlsDirective;

  @Input()
  public dataId = 'DEFAULT_PAGINATION_ID';

  @Input()
  public columns: Column[] = [];

  @Input()
  public rowConfig: RowConfig<any>;

  public displayType: typeof DisplayType = DisplayType;

  @Input()
  public groups: Group[] = [];

  @Input()
  public data: any[] = [];

  // Keep a formatted version of the data so we don't need to reformat
  // it when paginating.
  public formattedData: any[] = [];

  @Input()
  public itemsPerPage = defaultItemsPerPage;

  @Output()
  public filter = new EventEmitter<Filter>();

  @Output()
  public cellDataChange = new EventEmitter<CellChangeData>();

  @Input()
  public selectedFilters: Filter[] = [];

  @Input()
  public page = 1;

  @Input()
  public totalItems = undefined;

  public get showGroups(): boolean {
    return this.groups && this.groups.length > 0;
  }

  constructor(protected el: ElementRef, private detector: ChangeDetectorRef) { }

  // Because Angular does not detect changes to component properties of dynamically
  // instantiated components (ex. ChartView), we need to implement our own change
  // detection scheme.
  ngDoCheck() {
    if (this.data !== this.dataChanges.currentValue) {
      this.dataInitialized = true;
      this.dataChanges = new SimpleChange(this.dataChanges.currentValue, this.data, !this.dataInitialized);
      this.detector.markForCheck();
    }
  }

  ngOnInit() {
    // Set the maximum number of pagination buttons
    // so the paginator fits on mobile.
    this.paginator.maxSize = 5;
  }

  private getGroupIndexForColumn(column: Column) {
    return this.groups.findIndex((g: Group) =>
      g.columns.indexOf(column.key) > -1
    );
  }

  private getGroupClassName(column: Column) {
    const groupIndex = this.getGroupIndexForColumn(column);
    return `group-${groupIndex}`;
  }

  public groupClasses(group: Group) {
    const index = this.groups.indexOf(group);
    return `group-${index}`;
  }

  public headerClasses(column: Column) {
    const classes: any = {
      filtered: this.isFilterApplied(column.key)
    };
    classes[this.getGroupClassName(column)] = true;
    return classes;
  }

  public cellClasses(column: Column) {
    const classes: any = {
      audio: column.displayType === DisplayType.Audio
    };
    classes[this.getGroupClassName(column)] = true;
    return classes;
  }

  public paginate(page: number) {
    this.page = page;
  }

  public applyFilter(filters: Filter) {
    this.filter.emit(filters);
  }

  public findFilter(key: string): Filter | null {
    if (this.selectedFilters && this.selectedFilters.length > 0) {
      const index = this.selectedFilters.findIndex((f: Filter) => f.id === key);
      if (index > -1) {
        return this.selectedFilters[index];
      }
    }

    return null;
  }

  public getSelectedFilters(key: string): string[] {
    const filter = this.findFilter(key);
    return filter ? filter.selected : [];
  }

  public isFilterApplied(key: string): boolean {
    return !!this.findFilter(key);
  }

  public changeCellData(column: Column, rowIndex: any, oldValue: string, newValue: string) {
    // Just an optimization to prevent emitting when there's no change
    if (oldValue !== newValue) {
      const data = [...this.data];
      const row = { ...this.data[rowIndex] };
      row[column.key] = newValue;
      data[rowIndex] = row;
      this.data = data;
      this.cellDataChange.emit(new CellChangeData(
        this.chartId,
        row,
        rowIndex
      ));
    }
  }

  public dropdownPlacement(rowIndex: number): string[] {
    const middle = this.data.length / 2;
    if (rowIndex >= middle) {
      return ['top-left'];
    } else  {
      return ['bottom-left'];
    }
  }

}
