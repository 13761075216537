import { AuthService } from './auth.service';

import { environment } from '../../../environments/environment';

const parse = (url: string): string => {
  const withoutProtocol = url.split('://')[1];
  return withoutProtocol.split('/')[0];
};

// TODO Rename this file to `jwt-options-factory`
export function jwtOptionsFactory(authService: AuthService): {} {
  return {
    tokenGetter: () => {
      return authService.getAccessToken();
    },
    // TODO Test that these headers are set on each request
    globalHeaders: [{ 'Content-Type': 'application/json' }],
    whitelistedDomains: [
      parse(environment.apiBaseUrl)
    ]
  };
}
