import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';

import * as user from './user.actions';
import * as loader from './loader.actions';
import { State } from './user.reducer';
import { UserService } from '../service';

@Injectable()
export class UserEffects {
  @Effect()
  loadCommunities: Observable<Action> = this.actions
    .pipe(
      ofType(user.loadCommunities),

      tap(() => {
        this.store.dispatch(new loader.LoadStartAction(user.loadCommunities));
      }),
      switchMap((): Observable<Action> => {
        return this.userService.loadCommunities()
          .pipe(map(
            (data: any) => {
              this.store.dispatch(new loader.LoadStopAction(user.loadCommunities));
              return new user.LoadCommunitiesSuccessAction(data);
            },
            catchError((message) => {
              this.store.dispatch(new loader.LoadErrorAction(user.loadCommunities, message));
              return [
                new user.LoadCommunitiesFailAction([])
              ];
            })
          ));
      })
    );

  constructor(
    private actions: Actions,
    private store: Store<State>,
    private userService: UserService
  ) { }
}
