import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '../auth';
import { BasePowerProService } from './base-power-pro.service';

@Injectable()
export class UserService extends BasePowerProService {
  public communitiesUrl = `${this.baseUrl}/user/communities`;

  constructor(
    authHttp: HttpClient,
    authService: AuthService
  ) {
    super(authHttp, authService);
  }

  public loadCommunities(): Observable<{}> {
    return this.get(this.communitiesUrl);
  }
}
