import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AuthService } from '../shared';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private route: ActivatedRoute, private auth: AuthService) { }

  public ngOnInit() {
    const returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    this.auth.login(returnUrl);
  }
}
