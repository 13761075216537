import { Component, ElementRef, Input } from '@angular/core';

import { SidebarService } from '../../../../../layout/sidebar/sidebar.service';
import { D3Chart } from '../chart';

import { d3Selection, PercentBar } from 'power-chart';

@Component({
  selector: 'app-percent-bar',
  templateUrl: './percent-bar.component.html',
  styleUrls: ['./percent-bar.component.scss']
})
export class PercentBarComponent extends D3Chart {
  protected svg: any;
  protected chart: any;

  @Input()
  public label = '';

  @Input()
  public total: number | null = null;

  public get cssLabel(): string {
    return this.label.toLowerCase().replace(/[^a-z]+/g, '-');
  }

  // TODO Move some of the following into D3Chart?
  protected margin = { top: 0, right: 0, bottom: 0, left: 0 };
  protected padding = { top: 0, bottom: 20, left: 0, right: 0 };

  @Input()
  public disableTransitions = false;

  /*
   * Function for accessing the graphable value for each data point.
   */
  @Input()
  public valueAccessor: (d: any) => number = (d: any) => Number(d.count);

  /*
   * Width of the SVG element.
   */
  public get width(): number {
    return this.el.nativeElement.offsetWidth - this.margin.left - this.margin.right;
  }

  /*
   * Height of the SVG element.
   */
  public height = 10;

  constructor(el: ElementRef, sidebar: SidebarService) {
    super(el, sidebar);
  }

  initChart() {
    const svgEl = this.el.nativeElement.querySelector('[name=chart]');
    this.svg = d3Selection.select(svgEl);

    this.chart = new PercentBar();
    this.chart
      .valueAccessor(this.valueAccessor);

    if (this.disableTransitions) {
      this.chart
        .speed(0)
        .delay(0);
    }
  }

  updateChartData(data: any) {
    this.chart
      .title(this.label)
      .total(this.total)
      .showTotal(false)
      .width(this.width)
      .height(this.height)
      .render(this.svg, data)
    ;
  }

  resize() {}

}
