import { Component, ElementRef, Input } from '@angular/core';

import { SidebarService } from '../../../../../layout/sidebar/sidebar.service';
import { D3Chart } from '../chart';

import { d3Selection, DoughnutChart } from 'power-chart';

@Component({
  selector: 'app-d3-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class D3DoughnutChartComponent extends D3Chart {
  protected svg: any;

  protected chart: any;

  // TODO Move some of the following into D3Chart?
  protected margin = { top: 0, right: 2, bottom: 5, left: 0 };
  protected padding = { top: 0, bottom: 0, left: 0, right: 0 };

  @Input()
  public disableTransitions = false;

  public get transitionsShouldBeDisabled(): boolean {
    // Disable transitions if we specified that through the attribute
    // or if the chart is not currently in the viewport.
    return this.disableTransitions || !this.isInViewport(this.el.nativeElement);
  }

  @Input()
  public valueAccessor: (d: any) => number = (d: any) => Number(d.count);

  @Input()
  public labelAccessor: (d: any) => string = (d: any) => String(d.label);

  /*
   * The number of custom style classes to loop through when creating
   * bars.
   */
  @Input()
  public numberOfCustomStyles = 5;

  public get speed(): number {
    return this.transitionsShouldBeDisabled ? 0 : 600;
  };

  public delay = 100;

  private chartContainer: HTMLElement;

  /*
   * Width of the SVG element.
   */
  public get width(): number {
    return this.chartContainer.offsetWidth - this.margin.left - this.margin.right;
  }

  /*
   * Height of the SVG element.
   */
  public get height(): number {
    return Math.ceil(this.chartContainer.offsetHeight - this.margin.top - this.margin.bottom);
  }

  public get total(): number {
    return this.data.reduce((a: number, d: any) => a += this.valueAccessor(d), 0);
  }

  constructor(el: ElementRef, sidebar: SidebarService) {
    super(el, sidebar);
  }

  initChart() {
    this.chartContainer = this.el.nativeElement.querySelector('.svg-wrapper');

    // 0. Get the chart target SVG
    const svgEl = this.el.nativeElement.querySelector('[name=doughnut]');
    this.svg = d3Selection.select(svgEl);

    // 1. Create the chart
    this.chart = new DoughnutChart();

    // 2. Style the chart
    this.chart
      .valueAccessor(this.valueAccessor)
      .labelAccessor(this.labelAccessor)
      .speed(this.speed)
      .delay(this.delay)
    ;
  }

  updateChartData(data: any) {
    this.chart
      .width(this.width)
      .height(this.height)
      .speed(this.speed)
      .delay(this.delay)
      .render(this.svg, data);
  }

  resize() {
    this.chart
      .width(this.width)
      .height(this.height)
      .speed(0) // Animations off during resizing.
      .delay(0)
      .redraw(this.svg);
  }
}
