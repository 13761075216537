import { Injectable } from '@angular/core';

@Injectable()
export class DownloadStringService {
  download(content: string, fileName: string, mimeType = 'application/octet-stream') {
    const a = document.createElement('a');

    // IE 10
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(new Blob([content], {
        type: mimeType
      }), fileName);
    // HTML5
    } else if (URL && 'download' in a) {
      a.href = URL.createObjectURL(new Blob([content], {
        type: mimeType
      }));
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      // only this mime type is supported with location.href
      location.href = 'data:application/octet-stream,' + encodeURIComponent(content);
    }
  }
}
