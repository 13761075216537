import { Component, Input } from '@angular/core';

import { D3BarChartComponent } from '../bar-chart/bar-chart.component';
import { d3Selection, HistogramChart, YAxis, RoundedTopBars } from 'power-chart';

@Component({
  selector: 'app-histogram-bar-chart',
  templateUrl: './histogram-bar-chart.component.html',
  styleUrls: ['./histogram-bar-chart.component.scss']
})
export class HistogramBarChartComponent extends D3BarChartComponent {
  protected chartElementSelector = '[data-histogram-chart-container]';

  @Input()
  public binSize = 1;

  @Input()
  public minBin = null;

  @Input()
  public maxBin = null;

  initChart() {
    this.yAxisSelect = d3Selection.select(this.axisEl)
      .attr('height', this.height); // TODO Are these necessary?
    this.detailsSelect = d3Selection.select(this.detailsEl)
      .attr('height', this.height);

    // Draw the y axis so we can determine how much space it takes up.
    this.yAxis = new YAxis();
    this.yAxis
      .height(this.height)
      .showTicks(false)
      .render(this.yAxisSelect, []);

    // Draw the chart accounting for the y axis padding
    const detailsPad = {
      ...this.padding,
      top: this.padding.top + this.yAxis.requiredPadding().top
    };

    const barShape = new RoundedTopBars();
    barShape.cornerRadius(8);

    // TODO Min and max bins.
    // TODO Use full width.
    // TODO Min and max default values
    this.details = new HistogramChart();
    this.details
      .name(this.chartName)
      .barShape(barShape)
      .showValueAxisText(false)
      .cyclicClassCount(this.numberOfCustomStyles)
      // TODO Make the min width of the y axis the full width.
      .width(this.width)
      .height(this.height)
    // .useFixedBarStep(true) // TODO Get this working
      .barStep(this.binSize)
      .binSize(this.binSize)
      .minBin(this.minBin)
      .maxBin(this.maxBin)
      .dataInnerPadding(0.1)
      .dataOuterPadding(0.1)
      .padding(detailsPad)
      .valueAccessor(this.valueAccessor)
      .labelAccessor(this.labelAccessor)
      .categoryFormatter(this.categoryFormatter)
      .valueFormatter(this.valueFormatter)
      .render(this.detailsSelect, []);

    // if (this.speed !== undefined && this.speed !== null) {
    //   this.details.speed(this.speed);
    // }

    // Re-render the y axis to:
    // - shift left and down to by its required padding.
    // - reduce the height to account for the x axis.
    this.repositionAxis();
    this.yAxis
      .scale(this.details.valueScale())
      .redraw();

    this.svg = d3Selection.select(this.el.nativeElement);

    this.details.getRoot()
      .classed('details', true);
  }
}
