import { Pipe, PipeTransform } from '@angular/core';

import { Column } from './column';
import { TableFormatter } from './table-formatter';

@Pipe({
  name: 'tableFormatter'
})
export class TableFormatterPipe implements PipeTransform {
  transform(data: any[], columns: Column[]): any[] {
    return TableFormatter.data(data, columns);
  }
}
