import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  Directive,
  HostBinding,
  ElementRef,
  ViewChild,
} from '@angular/core';

export class DropdownButton implements OnInit {
  @HostBinding('attr.type') type: string | null = null;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (this.el.nativeElement.tagName.toLowerCase() === 'button') {
      this.type = 'button';
    }
  }
}

@Directive({
  selector: '[appDropdownButton]'
})
export class DropdownButtonDirective extends DropdownButton {
  constructor(el: ElementRef) {
    super(el);
  }
}

@Directive({
  selector: '[appDropdownItem]'
})
export class DropdownItemDirective extends DropdownButton {
  @HostBinding('class.dropdown-item') dropdown = true;

  constructor(el: ElementRef) {
    super(el);
  }
}

/**
 * Use this component to easily create a dropdown.
 *
 * Usage example:
 * ```
 *   <!--
 *   You can pass the following attributes to DropdownComponent:
 *    - placement: defines the location of the dropdown menu relative
 *                 to the toggle button. Options look like "left", "bottom-left",
 *                 "top-left", "top", etc.
 *   -->
 *   <app-dropdown placement="bottom-right">
 *
 *     <!--
 *     The element with the `appDropdownButton` attribute will be used as the menu toggle.
 *     If you give this element an id attribute, the menu dropdown will receive an `aria-labeledby`
 *     attribute pointing to this button.
 *     -->
 *     <button appDropdownButton id="foo">Open</button>
 *
 *     <!--
 *     Any elements with the `appDropdownItem` attribute will be placed in the menu as items.
 *     -->
 *     <button appDropdownItem>Action 1</button>
 *     <a appDropdownItem>Action 2</button>
 *
 *   </app-dropdown>
 * ```
 */
@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  @Input()
  public placement = 'bottom-left';

  @Input()
  public autoClose = true;

  @Input()
  container: string;

  @ViewChild('dropdown', {static: true})
  dropdown: any;

  @Output()
  openChange = new EventEmitter<boolean>();

  constructor(private el: ElementRef) { }

  ngOnInit() {
    // If an id attribute is set on the button, use it to label the menu.
    const button = this.el.nativeElement.querySelector('[appDropdownButton]');
    const id = button.getAttribute('id');

    if (id) {
      const menu = this.el.nativeElement.querySelector('[ngbDropdownMenu]');
      menu.setAttribute('aria-labeledby', id);
    }
  }

  onOpenChange(open: boolean) {
    this.openChange.emit(open);
  }

  close() {
    this.dropdown.close();
  }

  stopBubble(e: any) {
    if (!this.autoClose) {
      e.stopPropagation();
    }
  }
}
