import { Component, HostBinding } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { AuthService } from './shared';
import { WindowService } from './shared/service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @HostBinding('class.msie')
  public get isOldIE(): boolean {
    return this.windowService.isOldIE();
  }

  constructor(
    private auth: AuthService,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private windowService: WindowService
  ) {
    this.auth.handleAuthentication();
    this.auth.scheduleRenewal();
    this.angulartics2GoogleAnalytics.startTracking();
  }
}
