import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { retryBackoff } from 'backoff-rxjs';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';

function hasHttpStatus(error: any) {
  return error.status !== undefined;
}

function isBadRequest(error: any) {
  return error.status === 400;
}

function isNotFound(error: any) {
  return error.status === 404;
}

function isUnauthorized(error: any) {
  return error.status > 400 && error.status < 404;
}

function is4XXError(error: any) {
  return error.status >= 400 && error.status < 500;
}

export class BasePowerProService {
  public baseUrl = environment.apiBaseUrl;

  public maxRetries = 3;

  constructor(
    protected authHttp: HttpClient,
    protected authService: AuthService
  ) { }

  private handleResult(result: Observable<any>): Observable<any> {
    return result
      .pipe(
        retryBackoff({
          initialInterval: 500,
          maxRetries: this.maxRetries,
          shouldRetry: (error) => {
            if (hasHttpStatus(error)) {
              return !is4XXError(error);
            }
            // We should only retry for unexpected server responses.
            return true;
          }
        }),
        catchError(this.handleError),
        map(this.extractData)
      );
  }

  private getHeaders() {
    let headers = new HttpHeaders();
    const idToken = this.authService.getAccessToken();
    if (idToken) {
      headers = headers.append('Id-Token', idToken);
    }
    return headers;
  }

  protected get(url: string): Observable<any> {
    return this.handleResult(this.authHttp.get(url, { headers: this.getHeaders() }));
  }

  protected put(url: string, data?: any): Observable<{}> {
    return this.handleResult(this.authHttp.put(url, data, { headers: this.getHeaders() }));
  }

  protected post(url: string, filters?: any): Observable<any> {
    return this.handleResult(this.authHttp.post(url, filters, { headers: this.getHeaders() }));
  }

  protected delete(url: string): Observable<{}> {
    return this.handleResult(this.authHttp.delete(url, { headers: this.getHeaders() }));
  }

  private extractData(res: any): any {
    let body = {};
    if (res && res.body) {
      body = res.body;
    }
    return body;
  }

  // public so we can spy on it for specs
  public handleError = (error: HttpErrorResponse | any) => {
    let errMsg = 'Something bad happened. Please try again later.';

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      errMsg = `A unknown error occurred: ${error.error.message}`;
    } else if (hasHttpStatus(error)) {
      if (isUnauthorized(error)) {
        // Either not authorized or the server-side authentication has expired and we need to log in again.
        this.authService.logout();
        errMsg = 'You are not authorized for this action. Please login and try again.';
      } else if (isBadRequest(error)) {
        // The server should send back notifications in the reponse body.
        errMsg = '';
        if (error.error.notifications) {
          error.error.notifications.forEach((n: any) => errMsg += n.message);
        }
      } else if (isNotFound(error)) {
        errMsg = 'The record was not found. Please reload the page and try again.';
      }
    } else {
      // The backend returned an unsuccessful response code that we don't handle.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message
    console.error(errMsg);
    return observableThrowError(new Error(errMsg));
  }
}

