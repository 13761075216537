import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ViewChildren,
  QueryList
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2 } from 'angulartics2';

import {
  ChartDto,
  DashboardDto,
  DashboardFavoritesDto,
} from '@shared/dto';

@Component({
  selector: 'app-favorite-list',
  templateUrl: './favorite-list.component.html',
  styleUrls: ['./favorite-list.component.scss']
})
export class FavoriteListComponent {
  @Input() chart: ChartDto;

  get codename(): string {
    if (this.chart) {
      return this.chart.codename;
    } else {
      return '';
    }
  }

  get favorite(): boolean {
    for (const dashboardId in this.favorites) {
      const f = this.favorites[dashboardId];
      for (let i = 0; i < f.length; i++) {
        if (f[i].codename === this.codename) {
          return true;
        }
      }
    }

    return false;
  }

  @ViewChild('dropdown', {static: false})
  dropdown: any;

  editing = false;

  @Input()
  favorites: DashboardFavoritesDto;

  @Input()
  dashboards: DashboardDto[] = [];

  @Output()
  addChartToDefaultDashboard = new EventEmitter();

  @Output()
  addChartToDashboard = new EventEmitter();

  @Output()
  removeChartFromDashboard = new EventEmitter();

  @Output()
  createDashboard = new EventEmitter();

  get disabled() {
    return !(this.favorites && this.dashboards && this.chart) || null;
  }

  constructor(private angulartics2: Angulartics2) { }

  favoriteButtonText(dashboard: DashboardDto) {
    if (dashboard && dashboard.id) {
      return this.isOnDashboard(dashboard.id) ? 'Remove From' : 'Add To';
    }
    return '';
  }

  isOnDashboard(id: number) {
    const dashboard = this.favorites[id];
    if (dashboard) {
      return dashboard.findIndex((chart) => chart.codename === this.codename) > -1;
    }

    return false;
  }

  onFavoriteClick(dashboard: DashboardDto) {
    if (!dashboard) {
      this.addChartToDefaultDashboard.emit();
    } else if (dashboard.id && this.isOnDashboard(dashboard.id)) {
      this.removeChartFromDashboard.emit(dashboard);
    } else {
      this.addChartToDashboard.emit(dashboard);
    }
  }

  onCreateDashboard(e: any, name: string) {
    e.stopPropagation();
    e.preventDefault();

    if (name && name.trim()) {
      this.createDashboard.emit(name);
      this.dropdown.close();

      this.angulartics2.eventTrack.next({
        action: 'Create',
        properties: {
          category: 'Dashboard',
          label: this.codename,
        },
      });
    }
  }

  onOpenChange(open: boolean) {
    if (!open) {
      this.editing = false;
    }
  }

  showCreateDashboardForm() {
    this.editing = true;
  }
}
