export class Filter {
  static merge(filter: Filter, list: Filter[]): Filter[] {
    if (!list) {
      list = [];
    }

    // Remove the new filter from the list if it is already there.
    const out = list.filter((c: Filter) => c.id !== filter.id);

    // Add the new filter if it has selected items.
    if (filter.selected.length > 0) {
      out.push(filter);
    }

    return out;
  }

  constructor(public id: string, public selected: string[]) {}
}
