import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html'
})
export class AuthCallbackComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit() {
    // Only redirect if navigating to this page directly and not via Auth0
    if (window.location.hash.length === 0) {
      this.router.navigateByUrl('/');
    }
  }
}
