// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://report-api-dot-power-pro-dev.appspot.com/api',
  auth: {
    clientId: 'nXdtoEWpJVPXb8SLk1WiElwjCIdIIppe',
    domain: 'powerpro-dev.auth0.com',
    audience: 'https://horizon-api-dot-power-pro-dev.appspot.com/'
  },
  metrics: {
    google: {
      trackingId: 'UA-106144455-1'
    }
  }
};
