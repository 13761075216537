import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SidebarService {
  private _open = false;

  public get open(): boolean {
    return this._open;
  };

  public set open(value: boolean) {
    this._open = value;
    this.updateState();
  }

  public state: BehaviorSubject<string>;

  constructor() {
    this.state = new BehaviorSubject('open');
    this.updateState();
  }

  private updateState() {
    if (this._open) {
      this.opening();
    } else {
      this.closing();
    }
  }

  public finishedTransition() {
    if (this._open) {
      this.opened();
    } else {
      this.closed();
    }
  }

  public opening(): void {
    if (this.state.getValue() !== 'opening') {
      this.state.next('opening');
    }
  }

  public opened(): void {
    if (this.state.getValue() !== 'open') {
      this.state.next('open');
    }
  }

  public closing(): void {
    if (this.state.getValue() !== 'closing') {
      this.state.next('closing');
    }
  }

  public closed(): void {
    if (this.state.getValue() !== 'closed') {
      this.state.next('closed');
    }
  }
}
