import { HostBinding, Component } from '@angular/core';
import { CardComponent } from '../card.component';

@Component({
  selector: 'app-sub-card',
  templateUrl: './sub-card.component.html',
  styleUrls: ['./sub-card.component.scss']
})
export class SubCardComponent extends CardComponent {
  @HostBinding('class.card') card = false;

  @HostBinding('class.sub-card') subCard = true;

  constructor() {
    super();
  }
}
