import { Component, Input, ElementRef, OnDestroy } from '@angular/core';

import { SidebarService } from '../../../../../layout/sidebar/sidebar.service';
import { D3Chart } from '../chart';

import { d3Selection, uid, YAxis, RoundedTopBars, BarChart } from 'power-chart';

@Component({
  // TODO Remove the d3 name once we've removed ChartJS
  selector: 'app-d3-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class D3BarChartComponent extends D3Chart implements OnDestroy {
  protected details: any;

  protected yAxis: any;

  protected svg: any;

  protected detailsSelect: any;

  protected yAxisSelect: any;

  protected margin = { top: 0, right: 0, bottom: 0, left: 0 };

  protected padding = { top: 0, bottom: 20, left: 0, right: 0 };

  protected chartElementSelector = '[data-bar-chart-container]';

  public chartName = `details-${uid()}`;

  public detailsHidden = true;

  /*
   * The title to display above the zoom area.
   * It is used to describe what is being zoomed.
   * IE. "Displaying ${zoomTitle} 1 - 6"
   */
  @Input()
  // TODO Remove
  public zoomTitle = '';

  /*
   * This can be used to disable the zoom functionality and
   * only draw the details chart.
   */
  @Input()
  // TODO Remove
  public zoomEnabled = true;

  /*
   * The number of points to show when the data first loads.
   */
  @Input()
  // TODO Remove
  public initialZoom = 6;

  /**
   * The width of each bar in the bar chart. Be carefull about making
   * this much smaller as category titles do not adjust very well
   * when they are too long to fit this width.
   */
  @Input()
  public barStep = 194;

  /*
   * How fast the charts animate. Use 0 to turn off animations.
   */
  @Input()
  public speed: number;

  /*
   * The number of custom style classes to loop through when creating
   * bars.
   */
  @Input()
  public numberOfCustomStyles = 5;

  /*
   * Function used to format the label for use in the Y axis.
   */
  @Input()
  public valueFormatter: (d: string | number) => string;

  /*
   * Function used to format the label for use in the X axis.
   */
  @Input()
  public categoryFormatter: (d: string | number) => string;

  /*
   * Function for accessing the graphable value for each data point.
   */
  @Input()
  public valueAccessor: (d: any) => number = (d: any) => Number(d.count);

  /*
   * Function for accessing the label data for each data point.
   */
  @Input()
  public labelAccessor: (d: any) => string = (d: any) => String(d.label);

  /*
   * Width of the SVG element.
   */
  public get width(): number {
    return this.el.nativeElement.offsetWidth - this.margin.left - this.margin.right;
  }

  /*
   * Height of the SVG element.
   */
  public get height(): number {
    return Math.ceil(this.detailsScrollEl.offsetHeight - this.margin.top - this.margin.bottom);
  }

  protected get yAxisWidth() {
    return this.axisEl.getBoundingClientRect().width;
  }

  protected get axisEl() {
    return this.el.nativeElement.querySelector('[name=axis]');
  }

  protected get detailsEl() {
    return this.el.nativeElement.querySelector('[name=details]');
  }

  protected get detailsScrollEl() {
    return this.el.nativeElement.querySelector('[name=detailsScroll]');
  }

  constructor(el: ElementRef, sidebar: SidebarService) {
    super(el, sidebar);
  }

  public hasData(): boolean {
    return this.data && this.data.length > 0;
  }

  initChart() {
    this.yAxisSelect = d3Selection.select(this.axisEl)
      .attr('height', this.height); // TODO Are these necessary?
    this.detailsSelect = d3Selection.select(this.detailsEl)
      .attr('height', this.height);

    // Draw the y axis so we can determine how much space it takes up.
    this.yAxis = new YAxis();
    this.yAxis
      .height(this.height)
      .showTicks(false)
      .labelFormatter(this.valueFormatter)
      .render(this.yAxisSelect, []);

    // Draw the chart accounting for the y axis padding
    const detailsPad = {
      ...this.padding,
      top: this.padding.top + this.yAxis.requiredPadding().top
    };

    const barShape = new RoundedTopBars();
    barShape.cornerRadius(8);

    this.details = new BarChart();
    this.details
      .name(this.chartName)
      .barShape(barShape)
      .showValueAxisText(false)
      .cyclicClassCount(this.numberOfCustomStyles)
      // TODO Make the min width of the y axis the full width.
      .width(this.width)
      .height(this.height)
      .useFixedBarStep(true)
      // TODO Change this on mobile.
      .barStep(this.barStep)
      .dataInnerPadding(0.07)
      .dataOuterPadding(0.07)
      .padding(detailsPad)
      .valueAccessor(this.valueAccessor)
      .labelAccessor(this.labelAccessor)
      .categoryFormatter(this.categoryFormatter)
      .valueFormatter(this.valueFormatter)
      .render(this.detailsSelect, []);

    // if (this.speed !== undefined && this.speed !== null) {
    //   this.details.speed(this.speed);
    // }

    // Re-render the y axis to:
    // - shift left and down to by its required padding.
    // - reduce the height to account for the x axis.
    this.repositionAxis();
    this.yAxis
      .scale(this.details.valueScale())
      .redraw();

    this.svg = d3Selection.select(this.el.nativeElement);

    // TODO Allow setting class as part of BarChart configuration?
    this.details.getRoot()
      .classed('details', true);
  }

  repositionAxis() {
    const yAxisPad = {
      ...this.yAxis.requiredPadding(),
      bottom: this.details.categoryAxisPadding().bottom
    };

    this.yAxis
      .padding(yAxisPad);

    this.yAxisSelect
      .attr('width', yAxisPad.left);
  }

  syncDetailContainerDimensions() {
    const chartSelection = this.detailsSelect.select(this.chartElementSelector);
    const chartW = Math.ceil(chartSelection.node().getBBox().width);
    // const useW = Math.max(chartW, areaW);

    this.detailsSelect
      .attr('height', this.height)
      .attr('width', chartW);

    const areaW = this.width - this.yAxis.requiredPadding().left;
    if (chartW > areaW) {
      this.el.nativeElement.classList.add('hidden');
    } else {
      this.el.nativeElement.classList.remove('hidden');
    }
  }

  updateChartData(data: any) {
    this.detailsSelect
      .datum(data)
      .call(this.details.render);

    this.yAxis
      .scale(this.details.valueScale());

    this.yAxisSelect
      .datum(data)
      .call(this.yAxis.render);

    // Reset the chart width based on y axis dimensions.
    const yAxisPad = this.yAxis.requiredPadding();
    this.details
      .width(this.width - yAxisPad.left)
      .redraw();

    this.syncDetailContainerDimensions();
    this.repositionAxis();

    this.yAxisSelect.call(this.yAxis.render);
  }

  resize() {
    this.details
      .height(this.height)
      .redraw();

    this.yAxis
      .scale(this.details.valueScale())
      .height(this.height)
      .redraw();

    this.syncDetailContainerDimensions();
    this.repositionAxis();
    this.yAxis.redraw();
  }

  ngOnDestroy() { }
}
