import { Input, Output, Component, OnInit, HostBinding, EventEmitter } from '@angular/core';
import {
  ChartDto,
  DashboardDto,
  DashboardFavoritesDto
} from '@shared/dto';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() heading: string;

  @Input() chart: ChartDto;

  @Input() description: string;

  @Input() favorites: DashboardFavoritesDto;

  @Input() dashboards: DashboardDto[] = [];

  @Input() showFavorites = true;

  @Output()
  addChartToDashboard = new EventEmitter();

  @Output()
  removeChartFromDashboard = new EventEmitter();

  @Output()
  createDashboard = new EventEmitter();

  @HostBinding('class.card') card = true;

  constructor() { }

  ngOnInit() { }

  onAddChartToDashboard(dashboard: DashboardDto) {
    this.addChartToDashboard.emit(dashboard);
  }

  onRemoveChartFromDashboard(dashboard: DashboardDto) {
    this.removeChartFromDashboard.emit(dashboard);
  }

  onCreateDashboard(name: string) {
    this.createDashboard.emit(name);
  }
}
