import { Input, ElementRef, AfterContentInit, OnDestroy, DoCheck, SimpleChange } from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, skip } from 'rxjs/operators';
import { throttle } from 'lodash';

import { SidebarService } from '../../../../layout/sidebar/sidebar.service';

export class D3Chart implements AfterContentInit, OnDestroy, DoCheck {
  protected _initialized = false;

  private resizeHandler: any;

  @Input()
  public data: any;

  private dataChanges = new SimpleChange(null, null, false);

  private dataInitialized = false;

  // This is set to be > $t-l in SASS. That way
  // resize events don't slow down the page animation.
  // However, subclasses can modify this.
  protected resizeSpeed = 800;

  /**
   * Determine if the element specified is vertically situated within
   * the viewport (does not account for horizontal scroll at the moment).
   */
  protected isInViewport(element: HTMLElement): boolean {
    const bounds = element.getBoundingClientRect();
    const viewportH = (window.innerHeight || document.documentElement!.clientHeight);
    return bounds.top < viewportH && bounds.bottom > 0;
  }

  protected subscription: Subscription;

  constructor(protected el: ElementRef,
    protected sidebar: SidebarService
  ) {
    // Create the handler used to managing resize events.
    this.resizeHandler = throttle(() => {
      // Verify that the element is attached to the DOM before
      // doing any resizing. This is resolves an error in Firefox
      // when it tries to measure elements that aren't attached to
      // the DOM.
      if (this._initialized && this.isAttached(this.el.nativeElement)) {
        this.resize();
      }
    }, this.resizeSpeed, {leading: false, trailing: true});
  }

  // TODO Do we still need to use native DOM events or can we use @Output?
  protected dispatchEvent(name: string) {
    const event = new CustomEvent(name, { bubbles: true });
    this.el.nativeElement.dispatchEvent(event);
  }

  // Check to see if this component is currently attached to the DOM.
  // This allows us to bypass resize event handling when not attached
  // because it causes exceptions in Firefox when trying to measure
  // SVG elements that need to be resized.
  private isAttached(current: HTMLElement): boolean {
    if (current.parentElement) {
      return this.isAttached(current.parentElement);
    }

    return current.tagName.toLowerCase() === 'html';
  }

  public ngAfterContentInit() {
    if (!this.data) {
      this.data = [];
    }

    this.initChart();

    this.sidebar.state
      .pipe(
        skip(1),
        distinctUntilChanged()
      )
      .subscribe(this.resizeHandler);

    // TODO Do we want this window listener on all charts? If not, maybe it
    // should be its own directive?
    (window as any).addEventListener('resize', this.resizeHandler);

    this._initialized = true;

    this.updateChartData(this.data);
  }

  // Because Angular does not detect changes to component properties of dynamically
  // instantiated components (ex. ChartView), we need to implement our change
  // detection scheme.
  ngDoCheck() {
    if (this.data !== this.dataChanges.currentValue) {
      this.dataChanges = new SimpleChange(this.dataChanges.currentValue, this.data, !this.dataInitialized);
      this.dataInitialized = true;

      if (this._initialized) {
        this.updateChartData(this.data);
      }
    }
  }

  protected subscriptionUpdate(data: any) {
    this.updateChartData(data);
  }

  protected initChart() {}

  protected updateChartData(data: any) {}

  protected resize() {}

  public ngOnDestroy() {
    window.removeEventListener('resize', this.resizeHandler);

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

