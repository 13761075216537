import { Component, Input, ElementRef, OnDestroy } from '@angular/core';

import { SidebarService } from '../../../../../layout/sidebar/sidebar.service';
import { D3Chart } from '../chart';
import { d3Selection, uid, BarChart, Zoomable, RoundedRectBars } from 'power-chart';

@Component({
  // TODO Remove the d3 name once we've removed ChartJS
  selector: 'app-zoomable-bar-chart',
  templateUrl: './zoomable-bar-chart.component.html',
  styleUrls: ['./zoomable-bar-chart.component.scss']
})
export class ZoomableBarChartComponent extends D3Chart implements OnDestroy {
  private details: any;

  private overview: any;

  private zoomable: any;

  private svg: any;

  private detailsSelect: any;

  private overviewSelect: any;

  private margin = { top: 0, right: 0, bottom: 0, left: 0 };

  /*
   * The amount of space to place between the detail and overview charts.
   * TODO Separate the two charts into separate SVG elements with text in
   * between.
   */
  public chartSpace = 10;

  public chartName = `details-${uid()}`;

  public chartZoomName = `zoom-${uid()}`;

  public selectedPoints = [0, 0];

  public selectedLabels = ['', ''];

  public barMargin = 0.3;

  /*
   * The title to display above the zoom area.
   * It is used to describe what is being zoomed.
   * IE. "Displaying ${zoomTitle} 1 - 6"
   */
  @Input()
  public zoomTitle = '';

  /*
   * This can be used to disable the zoom functionality and
   * only draw the details chart.
   */
  @Input()
  public zoomEnabled = true;

  /*
   * How fast the charts animate. Use 0 to turn off animations.
   */
  @Input()
  public speed: number;

  /*
   * The number of custom style classes to loop through when creating
   * bars.
   */
  @Input()
  public numberOfCustomStyles = 5;

  /*
   * The number of points to show when the data first loads.
   */
  @Input()
  public initialZoom = 6;

  /*
   * Function used to format the label for use in the Y axis.
   */
  @Input()
  public yLabelFormatter: (d: string | number) => string;

  /*
   * Function used to format the label for use in the X axis.
   */
  @Input()
  public xLabelFormatter: (d: string | number) => string;

  /*
   * Function for accessing the graphable value for each data point.
   */
  @Input()
  public valueAccessor: (d: any) => number = (d: any) => Number(d.count);

  /*
   * Function for accessing the label data for each data point.
   */
  @Input()
  public labelAccessor: (d: any) => string = (d: any) => String(d.label);

  /*
   * The height of the details (zoomable) chart.
   */
  public get chartHeight(): number {
    if (this.zoomEnabled) {
      return this.height - this.zoomHeight - this.chartSpace - this.overviewTitleHeight;
    } else {
      return this.height;
    }
  }

  /*
   * The height of the overview chart (zoom area).
   */
  public get zoomHeight(): number {
    return 50;
  }

  /*
   * The height of the title element between the detail and overview charts.
   */
  public get overviewTitleHeight(): number {
    const title = this.el.nativeElement.querySelector('[name=overviewTitle]');
    if (title) {
      let height = title.offsetHeight;
      const styles = window.getComputedStyle(title);
      height += parseInt(styles.getPropertyValue('margin-top'), 10);
      height += parseInt(styles.getPropertyValue('margin-bottom'), 10);
      return height;
    }
    return 0;
  }

  /*
   * Width of the SVG element.
   */
  public get width(): number {
    return this.el.nativeElement.offsetWidth - this.margin.left - this.margin.right;
  }

  /*
   * Height of the SVG element.
   */
  public get height(): number {
    return this.el.nativeElement.offsetHeight - this.margin.top - this.margin.bottom;
  }

  // used for event registration.
  public updateSelected: (start: number, end: number) => void;


  constructor(el: ElementRef, sidebar: SidebarService) {
    super(el, sidebar);

    this.updateSelected = this._updateSelected.bind(this);
  }


  public hasData(): boolean {
    return this.data && this.data.length > 0;
  }

  public shouldZoom(): boolean {
    return this.zoomEnabled && this.hasData() && this.data.length > this.initialZoom;
  }

  private _updateSelected(start: number, end: number) {
    this.selectedPoints = [start, end];

    if (this.hasData()) {
      this.selectedLabels = [
        this.labelAccessor(this.data[start]),
        this.labelAccessor(this.data[end])
      ];
    } else {
      this.selectedLabels = ['', ''];
    }
  }

  public zoomStart() {
    return this.selectedPoints[0] + 1;
  }

  public zoomEnd() {
    return this.selectedPoints[1] + 1;
  }

  initChart() {
    this.details = new BarChart();
    this.details
      .name(this.chartName)
      .cyclicClassCount(this.numberOfCustomStyles)
      // .barMargin(this.barMargin)
      .width(this.width)
      // Start by hiding the zoom functionality.
      .height(this.height)
      .valueAccessor(this.valueAccessor)
      .labelAccessor(this.labelAccessor)
      .categoryFormatter(this.xLabelFormatter)
      .valueFormatter(this.yLabelFormatter);

    const roundedBars = new RoundedRectBars();
    roundedBars.cornerRadius(5);

    this.overview = new BarChart();
    this.overview
      .name(this.chartZoomName)
      // Start by hiding the zoom functionality.
      .visible(false)
      .showCategoryAxis(false)
      .showValueAxis(false)
      // .barMargin(0.6)
      .barShape(roundedBars)
      .width(this.width)
      .height(this.zoomHeight)
      .valueAccessor(this.valueAccessor)
      .labelAccessor(this.labelAccessor);

    // if (this.speed !== undefined && this.speed !== null) {
    //   this.details.speed(this.speed);
    //   this.overview.speed(this.speed);
    // }

    const detailsEl = this.el.nativeElement.querySelector('[name=details]');
    const overviewEl = this.el.nativeElement.querySelector('[name=overview]');

    this.detailsSelect = d3Selection.select(detailsEl)
      .attr('height', this.chartHeight)
      .datum([])
      .call(this.details.render);

    this.overviewSelect = d3Selection.select(overviewEl)
      .attr('height', this.zoomHeight)
      .datum([])
      .call(this.overview.render);

    this.svg = d3Selection.select(this.el.nativeElement);

    // TODO Allow setting class as part of BarChart configuration?
    this.details.getRoot()
      .classed('details', true);

    // Position the zoom chart.
    this.overview.getRoot()
      .classed('zoom', true);

    this.zoomable = new Zoomable();
    this.zoomable
      .snap(true)
      .overview(this.overview)
      .details(this.details)
      .on('select', this.updateSelected);

    this.zoomable.render();
  }

  updateChartData(data: any) {
    // TODO We should separate out the concept of enabling zoom
    // and the minimum amount of data the chart needs to have before
    // showing the zoom functionality. That way users of this component
    // can turn off zoom for a specific chart (which the code below
    // currently overrides).
    const end = Math.min(this.initialZoom, data.length);
    this.zoomEnabled = end < data.length;

    this.overview.visible(this.zoomEnabled);
    this.zoomable.enabled(this.zoomEnabled);

    this.details.height(this.chartHeight)
      .resetZoom();

    this.detailsSelect
      .attr('height', this.chartHeight)
      .datum(data)
      .call(this.details.render);

    this.overviewSelect
      .datum(data)
      .call(this.overview.render);

    this.zoomable.render();

    if (this.shouldZoom()) {
      this.zoomable.zoomToPoints(0, end - 1);
    }
  }

  resize() {
    this.details
      .width(this.width)
      .height(this.chartHeight);

    this.overview
      .width(this.width)
      .height(this.zoomHeight);

    this.detailsSelect
      .attr('height', this.chartHeight)
      .call(this.details.render);

    this.overviewSelect
      .call(this.overview.render);

    this.zoomable.render();

    if (this.shouldZoom()) {
      // TODO Once we migrate zoom to D3VisualBase, see if we can get
      // Zoomable to do this for us automatically.
      this.zoomable.zoomToPoints(this.selectedPoints[0], this.selectedPoints[1]);
    }
  }

  ngOnDestroy() {
    this.zoomable.removeListener('select', this.updateSelected);
  }
}
