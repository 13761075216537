import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Angulartics2Module } from 'angulartics2';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login';
import { AuthCallbackComponent, AuthGuard, AuthService, ScopeGuard } from './shared';
import { jwtOptionsFactory } from './shared/auth/auth-http-service.factory';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { isIE11, isOldIE, UserService, WindowService } from './shared/service';
import { metaReducers, reducers } from './shared/state/root.reducer';
import { UserEffects } from './shared/state/user.effects';

const enableStoreFreeze = !environment.production && !isOldIE() && !isIE11();

@NgModule({
  declarations: [
    AppComponent,
    AuthCallbackComponent,
    LoginComponent,
    LoadingComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthService]
      },
      config: {
        whitelistedDomains: [
          environment.apiBaseUrl
        ]
      }
    }),
    AppRoutingModule,
    SharedComponentsModule,
    Angulartics2Module.forRoot(),
    StoreModule.forRoot(
      reducers,
      {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: enableStoreFreeze,
          strictActionImmutability: true,
          strictStateSerializability: true,
          // TODO https://github.com/powerpro/horizon/issues/481
          strictActionSerializability: false
        }
      }
    ),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25 // Retains last 25 states
    }),
    EffectsModule.forRoot([UserEffects])
  ],
  providers: [
    AuthGuard,
    ScopeGuard,
    AuthService,
    UserService,
    WindowService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
