import { Directive, Input, ElementRef, Renderer2, OnChanges, HostBinding, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appChartLoader]'
})
export class ChartLoaderDirective implements OnChanges {
  private loaderElement: HTMLElement | null;

  private noDataElement: HTMLElement | null;

  private loadingTemplate = '<div name="loading" class="chart-loader chart-loader-loading">Loading...</div>';

  private noDataTemplate = `<div name="noData" class="chart-loader chart-loader-no-data">
      <i class="fas fa-exclamation-triangle"></i>
      <p>
        No Results? Try changing your date range or selected properties.
      </p>
    </div>`;

  @Input()
  @HostBinding('class.loading')
  public loading = false;

  @Input()
  @HostBinding('class.no-data')
  public empty = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  createElement(html: string): HTMLElement {
    const creator = document.createElement('div');
    creator.innerHTML = html;
    return creator.firstChild as HTMLElement;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loading && changes.loading.currentValue) {
      this.loading = changes.loading.currentValue;
    }

    if (changes.empty && changes.empty.currentValue) {
      this.empty = changes.empty.currentValue;
    }

    this.update();
  }

  private update() {
    if (this.loading) {
      this.showLoader();
    } else if (this.empty) {
      this.showNoData();
    } else {
      this.hideAll();
    }
  }

  showLoader() {
    this.hideAll();
    this.loaderElement = this.createElement(this.loadingTemplate);
    this.renderer.appendChild(this.el.nativeElement, this.loaderElement);
  }

  showNoData() {
    this.hideAll();
    this.noDataElement = this.createElement(this.noDataTemplate);
    this.renderer.appendChild(this.el.nativeElement, this.noDataElement);
  }

  private hideAll() {
    this.hideLoader();
    this.hideNoData();
  }

  private hideLoader() {
    if (this.loaderElement) {
      this.renderer.removeChild(this.el.nativeElement, this.loaderElement);
      this.loaderElement = null;
    }
  }

  private hideNoData() {
    if (this.noDataElement) {
      this.renderer.removeChild(this.el.nativeElement, this.noDataElement);
      this.noDataElement = null;
    }
  }
}
