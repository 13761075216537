import {
  Component,
  OnInit,
  ElementRef,
  HostBinding,
  EventEmitter,
  Output,
  Input
} from '@angular/core';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss']
})
export class CollapseComponent implements OnInit {
  @HostBinding('class.collapsed')
  public collapsed = false;

  @Input()
  public initialState = false;

  @HostBinding('class.open')
  public get open(): boolean {
    return !this.collapsed;
  }

  public collapseElement: HTMLElement;

  @Output()
  public collapseChange: EventEmitter<boolean> = new EventEmitter();

  constructor(public element: ElementRef) { }

  ngOnInit() {
    this.collapseElement = this.element.nativeElement.querySelector('[name=collapseWrapper]');
    this.collapsed = this.initialState;

    if (this.collapsed) {
      this.doCollapse(this.collapseElement, false);
    }
  }

  public toggleCollapsed() {
    if (this.collapsed) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  public collapse() {
    this.collapsed = true;
    this.doCollapse(this.collapseElement);
    this.collapseChange.emit(this.collapsed);
  }

  public expand() {
    this.collapsed = false;
    this.doExpand(this.collapseElement);
    this.collapseChange.emit(this.collapsed);
  }

  private doCollapse(element: HTMLElement, animate = true) {
    if (animate) {
      // get the height of the element's inner content, regardless of its actual size
      const sectionHeight = element.scrollHeight;

      // temporarily disable all css transitions
      const elementTransition = element.style.transition;
      element.style.transition = '';

      // on the next frame (as soon as the previous style change has taken effect),
      // explicitly set the element's height to its current pixel height, so we
      // aren't transitioning out of 'auto'
      requestAnimationFrame(function() {
        element.style.height = sectionHeight + 'px';
        element.style.transition = elementTransition;
        element.classList.add('closing');

        const handleTransitionEnd = () => {
          element.removeEventListener('transitionend', handleTransitionEnd);
          element.classList.remove('closing');
        };

        element.addEventListener('transitionend', handleTransitionEnd);

        // on the next frame (as soon as the previous style change has taken effect),
        // have the element transition to height: 0
        requestAnimationFrame(function() {
          element.style.height = 0 + 'px';
        });
      });
    } else {
      element.style.height = '0';
    }
  }

  public doExpand(element: HTMLElement) {
    // get the height of the element's inner content, regardless of its actual size
    const sectionHeight = element.scrollHeight;

    // have the element transition to the height of its inner content
    element.style.height = sectionHeight + 'px';
    element.classList.add('opening');

    const handleTransitionEnd = () => {
      // remove this event listener so it only gets triggered once
      element.removeEventListener('transitionend', handleTransitionEnd);
      element.classList.remove('opening');

      // remove "height" from the element's inline styles, so it can return to its initial value
      (element.style as any).height = null;
    };

    // when the next css transition finishes (which should be the one we just triggered)
    element.addEventListener('transitionend', handleTransitionEnd);
  }
}
