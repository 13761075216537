import { CommunityDto, DateRangeDto, LeasingAgentDto } from '../dto';
import * as user from './user.actions';
import { UniqueLeasingAgentDto } from '../dto/unique-leasing-agent.dto';

interface NamedItem {
  name: string;
}

export interface State {
  readonly initialized: boolean;
  readonly dateRange: DateRangeDto;
  readonly communities: CommunityDto[];
  readonly leasingAgents: LeasingAgentDto[];
  readonly uniqueLeasingAgents: UniqueLeasingAgentDto[];
  readonly selectedCommunities: CommunityDto[];
  readonly selectedCommunityLeasingAgents: UniqueLeasingAgentDto[];
  readonly selectedLeasingAgents: LeasingAgentDto[];
}

const endDate = (() => {
  const date = new Date();
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(0);
  return date;
})();
const startDate = (() => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
})();


export const initialState: State = {
  initialized: false,
  dateRange: {
    start: startDate.toISOString(),
    end: endDate.toISOString()
  },
  communities: [],
  leasingAgents: [],
  uniqueLeasingAgents: [],
  selectedCommunities: [],
  selectedCommunityLeasingAgents: [],
  selectedLeasingAgents: []
};

const sortByName = (a: NamedItem, b: NamedItem) =>
  a.name > b.name ? 1 : a.name < b.name ? -1 : 0;

export function getUniqueAgentsForCommunities(communities: CommunityDto[]): UniqueLeasingAgentDto[] {
  return communities.reduce(
    (accumulator: UniqueLeasingAgentDto[], community: CommunityDto) => {
      community.leasingAgents.forEach((agent: LeasingAgentDto) => {
        let found = false;

        accumulator.forEach((unique: UniqueLeasingAgentDto) => {
          if (unique.name === agent.name) {
            found = true;
            unique.communities.push({
              leasingAgentId: agent.id,
              communityId: community.id
            });
          }
        });

        if (!found) {
          accumulator.push({
            name: agent.name,
            communities: [{
              leasingAgentId: agent.id,
              communityId: community.id
            }]
          });
        }
      });

      return accumulator;
    }
    , []);
}

// De-dupe leasing agents into a single list of UniqueLeasingAgentDtos
export function uniqueLeasingAgentsReducer(state = initialState, action: user.LoadCommunitiesSuccessAction): State {
  return {
    ...state,
    uniqueLeasingAgents: getUniqueAgentsForCommunities(action.payload)
      .sort(sortByName)
  };
}

// Create a flat map of all the leasing agent objects
// within the communities.
export function leasingAgentsReducer(state = initialState, action: user.LoadCommunitiesSuccessAction): State {
  const allAgents: LeasingAgentDto[] = action.payload.reduce(
    (accumulator: LeasingAgentDto[], community: CommunityDto) =>
      accumulator.concat(community.leasingAgents)
    , []);

  return { ...state, leasingAgents: allAgents };
}

export function reducer(state = initialState, action: user.Actions): State {
  switch (action.type) {
    case user.initialize: {
      return { ...state, initialized: true };
    }

    case user.changeEndDate: {
      const dateRange = { ...state.dateRange, end: action.payload };
      return { ...state, dateRange: dateRange };
    }

    case user.changeStartDate: {
      const dateRange = { ...state.dateRange, start: action.payload };
      return { ...state, dateRange: dateRange };
    }

    case user.loadCommunitiesSuccess: {
      // Add the leasingAgents node
      const state1 = leasingAgentsReducer(state, action);
      // Add the uniqueLeasingAgents node
      const state2 = uniqueLeasingAgentsReducer(state1, action);
      // Add the communities node
      const state3 = {
        ...state2,
        communities: action.payload.slice().sort(sortByName)
      };

      return state3;
    }

    case user.selectCommunities: {
      return {
        ...state,
        selectedCommunities: action.payload,
        selectedCommunityLeasingAgents: getUniqueAgentsForCommunities(action.payload)
          .sort(sortByName)
      };
    }

    case user.selectLeasingAgents: {
      return { ...state, selectedLeasingAgents: action.payload };
    }

    default: {
      return state;
    }
  }
}

export const getInitialized             = (state: State) => state.initialized;
export const getCommunities             = (state: State) => state.communities;
export const getLeasingAgents           = (state: State) => state.leasingAgents;
export const getUniqueLeasingAgents     = (state: State) => state.uniqueLeasingAgents;
export const getSelectedCommunities     = (state: State) => state.selectedCommunities;
export const getSelectedLeasingAgents   = (state: State) => state.selectedLeasingAgents;
export const getDateRange               = (state: State) => state.dateRange;
export const getSelectedCommunityAgents = (state: State) => state.selectedCommunityLeasingAgents;

