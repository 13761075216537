import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Auth0 from 'auth0-js';
import { of, Subscription, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthService {
  private requestedScopes = 'openid email profile read:reports';

  public logoutRoute = '/login';

  // This is safe because callbacks have to be whitelisted in Auth0
  private callbackUrl = `${window.location.protocol}//${window.location.host}/auth-callback`;

  public fallbackUserName = 'name unknown';

  private auth0: Auth0.WebAuth | undefined;

  private refreshSubscription: Subscription | undefined;

  constructor(private router: Router) {}

  public getAuth0(): Auth0.WebAuth {
    if (this.auth0 == null) {
      this.auth0 = new Auth0.WebAuth({
        clientID: environment.auth.clientId,
        domain: environment.auth.domain
      });
    }
    return this.auth0;
  }

  public setAuth0(auth0: Auth0.WebAuth): void {
    this.auth0 = auth0;
  }

  public login(returnUrl: string): void {
    localStorage.setItem('auth.returnUrl', returnUrl);
    this.getAuth0().authorize(this.getAuthOptions());
  }

  private getAuthOptions(): any {
    const options: any = {
      responseType: 'token id_token',
      audience: environment.auth.audience,
      redirectUri: this.callbackUrl,
      scope: this.requestedScopes
    };

    // Deal with IE 10 :(
    const w: any = window;
    if (!w.crypto && !w.msCrypto) {
      options.nonce = this.getNonce();
      // https://auth0.com/docs/protocols/oauth2/oauth-state
      // For most basic cases, the state can be a nonce
      options.state = options.nonce;
    }

    return options;
  }

  public logout(): void {
    this.unscheduleRenewal();
    this.clearSession();
    this.router.navigate([this.logoutRoute]);
  }

  public handleAuthentication(): void {
    this.getAuth0().parseHash(this.getAuthOptions(), (err: null | Auth0.Auth0Error, authResult: null | Auth0.Auth0DecodedHash): void => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        this.handleAuthRedirect();
      } else if (err) {
        console.log(err);
        this.handleAuthRedirect();
      }
    });
  }

  public renewToken(): void {
    this.getAuth0().checkSession(this.getAuthOptions(), (err: null | Auth0.Auth0Error, authResult: Auth0.Auth0DecodedHash): void => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        console.log(err);
        this.handleAuthRedirect();
      }
    });
  }

  private handleAuthRedirect(): void {
    const returnUrl = localStorage.getItem('auth.returnUrl') || this.logoutRoute;
    localStorage.removeItem('auth.returnUrl');
    this.router.navigateByUrl(returnUrl);
  }

  public setSession(authResult: any): void {
    // Set the expire time for the access token
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());

    // Prefer to use the scopes on the auth result if available.
    const scopes = authResult.scope || this.requestedScopes || '';

    const name = authResult.idTokenPayload.name || authResult.idTokenPayload.email || authResult.idTokenPayload.nickname;

    localStorage.setItem('auth.access_token', authResult.accessToken);
    localStorage.setItem('auth.id_token', authResult.idToken);
    localStorage.setItem('auth.expires_at', expiresAt);
    localStorage.setItem('auth.scopes', JSON.stringify(scopes));
    localStorage.setItem('user.name', name);

    this.scheduleRenewal();
  }

  public clearSession(): void {
    localStorage.removeItem('auth.access_token');
    localStorage.removeItem('auth.id_token');
    localStorage.removeItem('auth.expires_at');
    localStorage.removeItem('auth.scopes');
    localStorage.removeItem('auth.nonce');
    localStorage.removeItem('user.name');
  }

  public isAuthenticated(): boolean {
    const expiresAt = this.getExpiresAt();
    const isExpired = new Date().getTime() >= expiresAt;
    if (isExpired) {
      this.clearSession();
    }
    return !isExpired;
  }

  private getExpiresAt(): number {
    return JSON.parse(localStorage.getItem('auth.expires_at') as string);
  }

  public userHasScopes(scopes: string[]): boolean {
    const grantedScopes = JSON.parse(localStorage.getItem('auth.scopes') as string).split(' ');
    return scopes.every(scope => grantedScopes.includes(scope));
  }

  public getAccessToken(): string | null {
    return localStorage.getItem('auth.access_token');
  }

  public getIdToken(): string | null {
    return localStorage.getItem('auth.id_token');
  }

  public getUserName(): string {
    return localStorage.getItem('user.name') || this.fallbackUserName;
  }

  public getNonce(): string {
    // nonce has to be persisted. https://auth0.com/docs/api-auth/tutorials/nonce
    const nonceKey = 'auth.nonce';
    let nonce = localStorage.getItem(nonceKey);
    if (!nonce) {
      // TODO Add more entropy for security. Possibly from https://github.com/keybase/more-entropy
      const rand = Math.floor(Math.random() * 1000 * new Date().getTime());
      nonce = rand.toString();
      localStorage.setItem(nonceKey, nonce);
    }

    return nonce;
  }

  public scheduleRenewal() {
    if (!this.isAuthenticated()) {
      return;
    }

    this.unscheduleRenewal();

    const expiresInObservable = of(this.getExpiresAt()).pipe(
      mergeMap(
        expiresAt => {
          const now = Date.now();
          // Use timer to track delay until expiration
          // to run the refresh at the proper time
          return timer(Math.max(1, expiresAt - now));
        }
      )
    );

    // Once the delay time from above is reached, get a new JWT and schedule additional refreshes.
    this.refreshSubscription = expiresInObservable.subscribe(
      () => {
        this.renewToken();
        this.scheduleRenewal();
      }
    );
  }

  public unscheduleRenewal() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }
}
