import { Injectable } from '@angular/core';

export function isOldIE(): boolean {
  return window.navigator.userAgent.indexOf('MSIE') >= 0;
}

export function isIE11(): boolean {
  return !!(window as any).MSInputMethodContext && !!(document as any).documentMode;
}

@Injectable()
export class WindowService {
  getWindow(): Window {
    return window;
  }

  getLocation(): Location {
    return window.location;
  }

  scrollTop() {
    const content = document.body.querySelector('#content-area');
    if (content) {
      content.scrollTop = 0;
    }
  }

  /**
   * Determines if the browser is IE pre 11.
   */
  isOldIE(): boolean {
    return isOldIE();
  }

  isIE11(): boolean {
    return isIE11();
  }

}
