import { Observable } from 'rxjs';

export enum DisplayType {
  Audio,
  Dropdown,
  PlainText
}

export class Column {
  static toCapitalizedWords(name: string): string {
    const words = name.match(/[A-Za-z][a-z]*/g) || [name];
    return words.map(Column.capitalize).join(' ');
  }

  static capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }

  constructor(
    public key: string,
    public label?: string | null,
    public format?: (value: any, row: any) => string,
    public filter?: Observable<string[]>,
    public displayType = DisplayType.PlainText,
    public dropdownOptions?: any[]
  ) {
    if (!this.label) {
      this.label = Column.toCapitalizedWords(this.key);
    }
  }
}
