import { Action } from '@ngrx/store';

import { CommunityDto } from '../dto';
import { LeasingAgentDto } from '../dto/leasing-agent.dto';

export const initialize             = '[User] Initialize user state';
export const changeEndDate          = '[User] Change end date';
export const changeStartDate        = '[User] Change start date';
export const loadCommunities        = '[User] Load communities';
export const loadCommunitiesFail    = '[User] Load communities fail';
export const loadCommunitiesSuccess = '[User] Load communities success';
export const selectCommunities      = '[User] Select communities';
export const selectLeasingAgents    = '[User] Select leasing agents';

export class InitializeUserStateAction implements Action {
  readonly type = initialize;
}

export class ChangeStartDateAction implements Action {
  readonly type = changeStartDate;

  constructor(public payload: string) { }
}

export class ChangeEndDateAction implements Action {
  readonly type = changeEndDate;

  constructor(public payload: string) { }
}

export class LoadCommunitiesAction implements Action {
  readonly type = loadCommunities;
}

export class LoadCommunitiesFailAction implements Action {
  readonly type = loadCommunitiesFail;

  constructor(public payload: CommunityDto[]) { }
}

export class LoadCommunitiesSuccessAction implements Action {
  readonly type = loadCommunitiesSuccess;

  constructor(public payload: CommunityDto[]) { }
}

export class SelectCommunitiesAction implements Action {
  readonly type = selectCommunities;

  constructor(public payload: CommunityDto[]) { }
}

export class SelectLeasingAgentsAction implements Action {
  readonly type = selectLeasingAgents;

  constructor(public payload: LeasingAgentDto[]) {}
}

export type Actions =
  InitializeUserStateAction
  | ChangeEndDateAction
  | ChangeStartDateAction
  | LoadCommunitiesAction
  | LoadCommunitiesFailAction
  | LoadCommunitiesSuccessAction
  | SelectCommunitiesAction
  | SelectLeasingAgentsAction;
