import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Filter } from '../filter';

@Component({
  selector: 'app-table-column-filter',
  templateUrl: './table-column-filter.component.html',
  styleUrls: ['./table-column-filter.component.scss']
})
export class TableColumnFilterComponent implements OnInit {
  @Input()
  public identifier: string;

  @Input()
  public filters: string[] = [];

  @Input()
  public selected: string[] = [];

  @Output()
  public filter: EventEmitter<Filter> = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  isSelected(filter: string) {
    return this.selected.indexOf(filter) > -1 ? true : null;
  }

  applyFilter(filter: string) {
    const selected = !this.isSelected(filter);

    if (selected) {
      this.selected.push(filter);
    } else {
      const index = this.selected.indexOf(filter);
      if (index > -1) {
        this.selected.splice(index, 1);
      }
    }

    this.filter.emit(
      new Filter(this.identifier, this.selected)
    );
  }

  stopBubble(e: any) {
    e.stopPropagation();
  }
}
