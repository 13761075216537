import {
  ChangeDetectionStrategy,
  Component,
  Output,
  EventEmitter
} from '@angular/core';
import { TableComponent } from './table.component';
import { PaginationDto } from '@shared/dto';

export const defaultItemsPerPage = 8;

@Component({
  selector: 'app-async-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsyncTableComponent extends TableComponent {
  @Output()
  public pageChange: EventEmitter<PaginationDto> = new EventEmitter();

  public paginate(page: number) {
    this.pageChange.emit({
      pageNumber: page,
      pageSize: this.itemsPerPage
    });
  }

}
