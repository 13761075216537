import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  ElementRef,
  Renderer
} from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, AfterViewInit {
  @Input() progress: Observable<number>;

  public start = true;

  public updating = false;

  public complete = false;

  constructor(private _host: ElementRef, private _renderer: Renderer) { }

  ngAfterViewInit() {}

  ngOnInit() {
    this.progress.subscribe((value) => {
      this.complete = value === 100;
      this.start = value <= 1;
      this.updating = !(this.start || this.complete);

      this._renderer.setElementClass(this._host.nativeElement, 'start', this.start);
      this._renderer.setElementClass(this._host.nativeElement, 'complete', this.complete);
      this._renderer.setElementClass(this._host.nativeElement, 'updating', this.updating);
      this._renderer.setElementStyle(this._host.nativeElement, 'width', `${value}%`);
    });
  }
}
