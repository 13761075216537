import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class PageService {
  constructor(private router: Router) {}

  // TODO Testing
  public isRouteActive(url: string, exact = true): boolean {
    const queryParamsIndex = this.router.url.indexOf('?');
    let baseUrl = queryParamsIndex === -1 ? this.router.url : this.router.url.slice(0, queryParamsIndex);
    if (baseUrl.indexOf('/') === 0) {
      // Strip the baseUrl starting '/' before compare
      baseUrl = baseUrl.substring(1);
    }

    return exact
      ? baseUrl === url
      : baseUrl.indexOf(url) > -1;
  }
}
