import { Action } from '@ngrx/store';

export const start    = '[Loader] Start loader progress';
export const progress = '[Loader] Loader progress update';
export const stop     = '[Loader] Stop loader progress';
export const error    = '[Loader] Error';

export class LoadStartAction implements Action {
  readonly type = start;

  constructor(public id: string) {}
}

export class LoadStopAction implements Action {
  readonly type = stop;

  constructor(public id: string) {}
}

export class LoadErrorAction implements Action {
  readonly type = error;

  constructor(public id: string, public message: string) {}
}

export type Actions = LoadStartAction | LoadStopAction | LoadErrorAction;
