import { Column } from './column';

export class TableFormatter {
  static data(data: any[], columns: Column[]): string[][] {
    if (data && data.map) {
      return data.map((item: any) => {
        return columns.map((column: Column) => {
          return TableFormatter.cell(item, column);
        });
      });
    } else {
      return [];
    }
  }

  static cell(item: any, column: Column): string {
    if (item) {
      if (column.format) {
        return column.format(item[column.key], item);
      } else if (item[column.key] != null) {
        return String(item[column.key]);
      }
    }
    return '';
  }

  static headers(columns: Column[]): string[] {
    return columns.map((column: any) => column.label);
  }
}

