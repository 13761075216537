import {
  createSelector,
  createFeatureSelector,
  ActionReducer,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../../environments/environment';

/**
 * storeLogger automatically logs actions and state changes for convenience during dev.
 */
import { storeLogger } from 'ngrx-store-logger';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
import * as fromUser from './user.reducer';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  user: fromUser.State;
}

/**
 * Because metareducers take a reducer function and return a new reducer,
 * we can use our compose helper to chain them together. Here we are
 * using combineReducers to make our top level reducer, and then
 * wrapping that in storeLogger. Remember that compose applies
 * the result from right to left.
 */
export const reducers: any = {
  user: fromUser.reducer
};

/**
 * Anonymous arrow functions can't be used with AOT so storeLogger must be wrapped.
 * see: https://github.com/ngrx/platform/issues/170#issuecomment-318031101
 * see: https://github.com/btroncone/ngrx-store-logger/issues/21
 */
export function logger(reducer: ActionReducer<State>): ActionReducer<any, any> {
  return storeLogger({ collapsed: true })(reducer);
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [logger]
  : [];

/**
 * A selector function is a map function factory. We pass it parameters and it
 * returns a function that maps from the larger state tree into a smaller
 * piece of state. This selector simply selects the `books` state.
 *
 * Selectors are used with the `select` operator.
 *
 * ```ts
 * class MyComponent {
 *   constructor(state: Observable<State>) {
 *     this.reportFilterState = state.select(getReportFilterState);
 *   }
 * }
 * ```
 */
export const getUserState    = createFeatureSelector<fromUser.State>('user');

/**
 * Every reducer module exports selector functions, however child reducers
 * have no knowledge of the overall state tree. To make them useable, we
 * need to make new selectors that wrap them.
 *
 * The createSelector function from the reselect library creates
 * very efficient selectors that are memoized and only recompute when arguments change.
 * The created selectors can also be composed together to select different
 * pieces of state.
 */

// --------------
// User Selectors
// --------------
export const getUserStateInitialized    = createSelector(getUserState, fromUser.getInitialized);
export const getUserDateRange           = createSelector(getUserState, fromUser.getDateRange);
export const getUserCommunities         = createSelector(getUserState, fromUser.getCommunities);
export const getLeasingAgents           = createSelector(getUserState, fromUser.getLeasingAgents);
export const getUniqueLeasingAgents     = createSelector(getUserState, fromUser.getUniqueLeasingAgents);
export const getSelectedCommunities     = createSelector(getUserState, fromUser.getSelectedCommunities);
export const getSelectedLeasingAgents   = createSelector(getUserState, fromUser.getSelectedLeasingAgents);
export const getSelectedCommunityAgents = createSelector(getUserState, fromUser.getSelectedCommunityAgents);

