import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// Initialize Google Analytics with correct tracking ID for the environment
(window as any).ga('create', environment.metrics.google.trackingId, 'auto');

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

